
.side-bar {
    display: flex;
    flex-direction: column;
    width: 15vw;
    height: 100vh;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1), 0 2px 5px rgba(0,0,0,0.2);
    background-color: #3D5E75;
    color: #ffffff;
  }
  
  .side-bar-body {
    padding-left: 10px;
    font-family: 'Lato', sans-serif;
  }

  .side-bar-body h3 {
    font-size: 16pt;
    margin-bottom: 0;
  }
  
  .api-link {
    margin-top: 10px;
    font-size: 12pt;
    transition: all .1s ease;
    padding-left: 10px;
  }
  
  .api-link:hover {
    cursor: pointer;
    border-left: 5px solid #B32727;
    transition: all .1s ease;
  }
  
  #api-data {
    width: 100%;
    padding-left: 30px;
    padding-top: 30px;
    overflow-y: scroll;
  }

  
  .side-bar-header {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    color: whitesmoke;
    background-color: #193057;
    border-bottom: 5px solid #B32727;
    font-family: 'Righteous', cursive;
    text-align: center;
  }

  .side-bar-header h1 {
    color: #D4BE7D;
    margin: 0;
    font-size: 24pt;
    letter-spacing: .1em;
    text-transform: uppercase;
  }

  .side-bar-header h3 {
    font-size: 10pt;
    font-family: 'Lato', sans-serif;
  }

  .side-bar-header img {
    display: block;
    width: 220px;
    height: 220px;
    max-width: 100%;
    max-height: 100%;
    filter: invert(100%);
  }